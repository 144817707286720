import "./src/styles/global.css"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ENV from "./src/settings/env";
import React from "react"

export const wrapRootElement = ({ element }) => (
   <GoogleReCaptchaProvider reCaptchaKey={ENV.recaptchaSiteKey}>
       { element }
   </GoogleReCaptchaProvider>
)
