exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clinical-solutions-tsx": () => import("./../../../src/pages/clinical-solutions.tsx" /* webpackChunkName: "component---src-pages-clinical-solutions-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-links-tsx": () => import("./../../../src/pages/marketing-links.tsx" /* webpackChunkName: "component---src-pages-marketing-links-tsx" */),
  "component---src-pages-our-partners-tsx": () => import("./../../../src/pages/our-partners.tsx" /* webpackChunkName: "component---src-pages-our-partners-tsx" */),
  "component---src-pages-partnership-program-tsx": () => import("./../../../src/pages/partnership-program.tsx" /* webpackChunkName: "component---src-pages-partnership-program-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-the-ocumetra-effect-tsx": () => import("./../../../src/pages/the-ocumetra-effect.tsx" /* webpackChunkName: "component---src-pages-the-ocumetra-effect-tsx" */),
  "component---src-pages-the-science-behind-tsx": () => import("./../../../src/pages/the-science-behind.tsx" /* webpackChunkName: "component---src-pages-the-science-behind-tsx" */),
  "component---src-pages-what-sets-us-apart-tsx": () => import("./../../../src/pages/what-sets-us-apart.tsx" /* webpackChunkName: "component---src-pages-what-sets-us-apart-tsx" */)
}

